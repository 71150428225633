<template>
  <div />
</template>

<script>
export default {
  name: 'EmailComponent',
  data() {
    return {
      flag: false
    };
  },
  created() {
    if (window.history.state.marketId) {
      this.$router
        .push({
          name: 'ProductTracker',
          state: { countryId: window.history.state.marketId, device: window.history.state.device }
        })
        .catch(() => {});
    } else if (this.$route.params.mode && parseInt(this.$route.params.mode, 16) === 1) {
      this.$router
        .push({
          name: 'Registration',
          state: { searchRegistration: this.$route.params.id }
        })
        .catch(() => {});
    } else if (this.$route.params.mode && parseInt(this.$route.params.mode, 16) === 2) {
      this.$router
        .push({
          name: 'RegulatoryUpdates',
          state: { serviceId: this.$route.params.id,
            service: 'Regulatory Watch'
           }
        })
        .catch(() => {});
    } else {
      this.$router.push('/layout/dashboard').catch(() => {});
    }
  }
};
</script>
<style></style>
