/**
 * @author SwathiDivya Bhavaraju
 * @email swathidivya.bhavaraju@ul.com
 * @create date 2020-04-15 13:14:39
 * @modify date 2020-04-15 13:14:39
 * @desc [description]
 */
import axios from 'axios';

/* getUserData get api
 * to get the user data */
export async function getUserData() {
  let result = '';
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/user_data`)
    .then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data;
        localStorage.setItem('userId', result.id);
        localStorage.setItem('userData', JSON.stringify(result));
      }
    })
    .catch((err) => {
      console.log('get user_data java api error', err); // eslint-disable-line no-console
    });
  return result;
}
/* getUserData get api
 * to get the user data */
export async function getProfileData() {
  let result = '';
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/profile_data`)
    .then((response) => {
      if (response.status === 200 && response.data.status !== 'invalid') result = response.data;
    })
    .catch((err) => {
      console.log('get profile_data java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/* updateProfileData put api
 * to update the user data */
export async function updateProfileData(data) {
  let result = false;
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/profile_data`, data)
    .then((response) => {
      if (response.status === 200) {
        result = true;
      }
    })
    .catch((err) => {
      console.log('put profile_data java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/* userEmailAlertsPreferences get api
 * to get the user Email Alerts Preferences */
export async function getUserEmailAlertsPreferences() {
  let result = {};
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/user_email_alert_preferences`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('get user_email_alert_preferences java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/* userEmailAlertsPreferences put api
 * to update the user Email Alerts Preferences */
export async function updateUserEmailAlertsPreferences(data) {
  let result = {};
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/user_email_alert_preferences`, data)
    .then((response) => {
      if (response.status === 200 && response.data.status) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('put user_email_alert_preferences java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/* userPreference get api
 * to get the user Preferences */
export async function getUserPreference() {
  let result = '';
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/user_preference`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('get user_preference java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/* userPreference put api
 * to update the user Preferences */
export async function updateUserPreference(data) {
  let result = {};
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/user_preference`, data)
    .then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('put user_preference java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/* getUserCertificateAlerts get api
 * to get User Certificate Alerts */
export async function getUserCertificateAlerts() {
  let result = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/user_certificate_alerts`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('get user_certificate_alerts java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/* setUserCertificateAlerts put api
 * to update User Certificate Alerts */
export async function updateUserCertificateAlerts(data) {
  let result = {};
  await axios
    .put(`${process.env.VUE_APP_JAVA_API_URL}/user_certificate_alerts`, data)
    .then((response) => {
      if (response.status === 200 && response.data) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log(' put user_certificate_alerts java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/* getRolesList get api
 * to get roles list */
export async function getRolesList(accountTypeId = null) {
  let result = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/roles/${accountTypeId || JSON.parse(localStorage.getItem('userData')).account_type_id}`)
    .then((response) => {
      if (response.status === 200) {
        result = response.data;
      }
    })
    .catch((err) => {
      console.log('get roles java api error', err); // eslint-disable-line no-console
    });
  return result;
}

/* getUserList get api
 * to get Users List */
export async function getUserList() {
  let result = [];
  await axios
    .get(`${process.env.VUE_APP_JAVA_API_URL}/users`)
    .then((response) => {
      if (response.status === 200 && response.data.user_data) {
        result = response.data.user_data;
      }
    })
    .catch((err) => {
      console.log('users java api error', err); // eslint-disable-line no-console
    });
  return result;
}
